import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import ServicePage from "../../components/service";


export default function EnvironmentalImpactPage() {
    const {service} = useStaticQuery(graphql`
        query {
          service: servicesJson(key: {eq: "environmental-impact"}) {
            title
            title_long
            thumbnail {
              path {
                childImageSharp {
                  gatsbyImageData(width: 1024)
                }
              }
            }
            projects {
              title
              client
              location
            }
          }
        }
    `)
    return (
        <ServicePage service={service}>
            <p>
                REC has completed various environmental impact assessments including CEAA screening reports on federal lands, Biophysical Impact Assessments, Model Class Screening Reports (MCSR)/Preapproved Routine Impact Assessment (PRIA).  The EIA process involves collecting baseline data, assessing potential project impacts on environmental components and recommending mitigations to reduce project impacts.
            </p>
        </ServicePage>
    )
}
